<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Privacy Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- <section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>1.What is Privacy Policy?</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </div>
        
        <div class="privacy-item">
            <h2>2.To whom does this Privacy Policy apply?</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose
            </p>
        </div>

        <div class="privacy-item">
            <h2>3.What personal data do we process?</h2>

            <ul>
                <li><i class="icofont-simple-right"></i> Name, email address and/or your photo when you visit our exhibitions and make use of the technologies we offer to you to receive a Good GIF</li>
                <li><i class="icofont-simple-right"></i> Personal data obtained through or generated on our website, newsletters, commercial emails</li>
                <li><i class="icofont-simple-right"></i> IP address</li>
                <li><i class="icofont-simple-right"></i> Your browsing behaviour on the website such as information on your first visit</li>
                <li><i class="icofont-simple-right"></i> Whether you open a newsletter of email and what sections you select</li>
            </ul>
        </div>
    </div>
</section> -->


<section class="privacy-area pt-100">
    <div class="container">
        <!-- 1. Introduction -->
        <div class="privacy-item">
            <h2>1. Introduction</h2>
            <p>
                The Law Chambers of U.J.Associates  and its affiliates are committed to protecting your privacy and have provided this policy to familiarize you with the manner in which it collects, uses, shares, and discloses the information that is collected through 
                <a href="/" target="_blank" rel="noopener noreferrer">https://www.ujassociates.com/</a>.
            </p>
        </div>

        <!-- 2. Terms of the Policy -->
        <div class="privacy-item">
            <h2>2. Terms of the Policy</h2>
            <p>
                The terms of the Policy provided herein govern your use of the Website and any content provided, accessed, or distributed on the Website. For our client confidentiality obligations and associated references, please refer to our terms of engagement herein below.
            </p>
            <p>
                This Policy shall be construed to be provided in compliance with the Information Technology Act 2000 and the rules framed thereunder (as amended from time to time) (“IT Act”). The words and expressions used in this Policy but not defined herein will have the meaning assigned to them under the IT Act.
            </p>
        </div>

        <!-- 3. Information We Collect -->
        <div class="privacy-item">
            <h2>3. Information We Collect</h2>
            <p>
                During the use of the website we may collect the following types of information (“Personal Information”):
            </p>
            <ul>
                <li>
                    Contact information, such as your name, job title, and postal address, including your home address, business address, telephone number, mobile phone number, fax number, and email address;
                </li>
                <li>
                    Further business information necessarily processed in a project or client contractual relationship with The Law Chambers of U.J.Associates, or voluntarily provided by you, such as instructions given, payments made, requests, and projects;
                </li>
                <li>
                    Your password for the Website or other password-protected platforms or services, where you have one;
                </li>
                <li>
                    Information collected from publicly available resources, integrity databases, and credit agencies;
                </li>
                <li>
                    Information about relevant and significant litigation or other legal proceedings against you or a third party related to you and interaction with you which may be relevant for antitrust purposes, if legally required for compliance purposes;
                </li>
                <li>
                    Information about your health for the purpose of identifying and being mindful of any disabilities or special dietary requirements you may have, which we may ask in connection with the registration for and provision of access to an event or seminar. Any use of such information is based on your consent. If you do not provide such information, we will not be able to take relevant precautions;
                </li>
                <li>
                    Information about your experience, qualifications, and desired position within The Law Chambers of U.J.Associates, which you may provide in connection with any application made to The Law Chambers of U.J.Associates, any use of such information is based on your consent;
                </li>
                <li>
                    Details of your visits to our premises.
                </li>
                <li>
                    Other personal data regarding your preferences notwithstanding its relevance to the legal services that we provide; and/or
                </li>
                <li>
                    From time to time, it may include personal data about your membership of a professional or trade association or union, personal health data, details of dietary preferences when relevant to events to which we invite you, and details of any criminal record you may have.
                </li>
            </ul>
        </div>

        <!-- 4. Confidentiality of Personal Information -->
        <div class="privacy-item">
            <h2>4. Confidentiality of Personal Information</h2>
            <p>
                Your Personal Information will be kept confidential to the maximum possible extent and will be used to support your relationship with The Law Chambers of U.J.Associates, any Sensitive Personal Information will be kept confidential as required under applicable Indian laws.
            </p>
        </div>

        <!-- 5. Public Content -->
        <div class="privacy-item">
            <h2>5. Public Content</h2>
            <p>
                Any comments, messages, blogs, scribbles, etc posted/uploaded/conveyed/communicated by users on the public sections of the Website becomes published content and is not considered personal information subject to this Policy.
            </p>
        </div>

        <!-- 6. Circumstances of Data Collection -->
        <div class="privacy-item">
            <h2>6. Circumstances of Data Collection</h2>
            <p>
                We may collect personal data about you in a number of circumstances, including:
            </p>
            <ul>
                <li>
                    When you or your organization browse, make an inquiry, or otherwise interact on the Website;
                </li>
                <li>
                    When you attend a seminar or another The Law Chambers of U.J.Associates event or sign up to receive personal data from us, including for training purposes; or
                </li>
                <li>
                    When you or your organization offer to provide services to us.
                </li>
            </ul>
        </div>

        <!-- 7. Third-Party Data Sources -->
        <div class="privacy-item">
            <h2>7. Third-Party Data Sources</h2>
            <p>
                In some circumstances, we collect personal data about you from a third-party source, including personal data from your organization, other organizations with whom you have dealings, inter alia, government agencies, credit reporting agencies, information or service providers, or from publicly available records.
            </p>
        </div>

        <!-- 8. Voluntary Provision of Data -->
        <div class="privacy-item">
            <h2>8. Voluntary Provision of Data</h2>
            <p>
                As a general principle, you will provide us with your personal data entirely voluntarily. There are generally no detrimental effects for you if you choose not to consent or to provide personal data.
            </p>
        </div>

        <!-- 9. Use of Personal Information -->
        <div class="privacy-item">
            <h2>9. Use of Personal Information</h2>
            <p>
                The Law Chambers of U.J.Associates do not sell or rent Personal Information. We may use your Personal Information for the following purposes only (“Permitted Purposes”):
            </p>
            <ul>
                <li>
                    To verify whether you are entitled to access and use the Website and the services made available through the Website. This Personal Information may also be used to enable The Law Chambers of U.J.Associates, to enhance your experience of the Website.
                </li>
                <li>
                    To provide legal advice or other services or things you may have requested, including online or legal technology services or solutions as instructed or requested by you or your organization;
                </li>
                <li>
                    To manage and administer your or your organization’s business relationship with The Law Chambers of U.J.Associates, including processing payments, accounting, auditing, billing, and collection and support services;
                </li>
                <li>
                    To comply with our legal obligations (such as record keeping obligations), compliance screening or recording obligations (including obligations under antitrust laws, export laws, trade sanction, and embargo laws and for anti-money laundering, financial and credit check and fraud, and crime prevention and detection purposes), which may include automated checks of your contact data or other information you provide about your identity against applicable sanctioned-party lists and may contact you to confirm your identity in case of a potential match or recording interaction with you which may be relevant for compliance purposes;
                </li>
                <li>
                    To analyse and improve our services and communications with you;
                </li>
                <li>
                    To protect the security of and manage access to our premises, information Technology and communication systems, online platforms, websites, and other systems to prevent and detect security threats, fraud, or other criminal or malicious activities;
                </li>
                <li>
                    For insurance purposes;
                </li>
                <li>
                    To monitor and assess compliance with our policies and standards;
                </li>
                <li>
                    To identify persons authorized to trade on behalf of our clients, customers, suppliers, and/or service providers;
                </li>
                <li>
                    To comply with our legal and regulatory obligations and requests anywhere in the world, including reporting to and/or being audited by national and international regulatory bodies;
                </li>
                <li>
                    To enable vendors and service providers to provide various services subscribed to by you including contact information verification, payment processing, customer service, website hosting, data analysis, infrastructure provision, information technology services, and other similar services, The Law Chambers of U.J.Associates, may disclose your Personal Information to its affiliates, group companies, consultants, vendors, and contractors under confidentiality restrictions;
                </li>
                <li>
                    To comply with court orders and exercise and/or defend The Law Chambers of U.J.Associates legal rights; and
                </li>
                <li>
                    For any purpose related and/or ancillary to any of the above or any other purpose for which your Personal Information was provided to us.
                </li>
            </ul>
        </div>

        <!-- 10. Consent-Based Processing -->
        <div class="privacy-item">
            <h2>10. Consent-Based Processing</h2>
            <p>
                Where you have expressly given us your consent, we may process your Personal Information also for the following purposes:
            </p>
            <ul>
                <li>
                    Communicating with you through the channels you have approved to keep you up to date on the latest legal developments, announcements, and other information about The Law Chambers of U.J.Associates services, products, and technologies (including client briefings, newsletters, and other information) as well as The Law Chambers of U.J.Associates, events and projects;
                </li>
                <li>
                    Informing you of customer surveys, marketing campaigns, market analysis, sweepstakes, contests, or other promotional activities or events; or
                </li>
                <li>
                    Collecting information about your preferences to create a user profile to personalize and foster the quality of our communication and interaction with you (for example, by way of newsletter tracking or website analytics).
                </li>
            </ul>
        </div>

        <!-- 11. Marketing Communications -->
        <div class="privacy-item">
            <h2>11. Marketing Communications</h2>
            <p>
                With regard to marketing-related communication, we will, where legally required, only provide you with such information after you have opted in and provide you the opportunity to opt out anytime if you do not want to receive further marketing-related communication from us. We will not use your Personal Information for taking any automated decisions affecting you or creating profiles other than as described above.
            </p>
        </div>

        <!-- 12. Legal Grounds for Processing -->
        <div class="privacy-item">
            <h2>12. Legal Grounds for Processing</h2>
            <p>
                We may process your personal data, depending on the Permitted Purpose we use your personal data for, on one or more of the following legal grounds:
            </p>
            <ul>
                <li>
                    To perform and carry our client instructions since processing is necessary for the performance of client instructions or another contract with you or your organization.
                </li>
                <li>
                    To comply with our legal obligations (example to keep pension records or records for tax purposes); or
                </li>
                <li>
                    For the purposes of our legitimate interest or those of any third-party recipients that receive your personal data, provided that such interests are not overridden by your interests or fundamental rights and freedoms.
                </li>
            </ul>
        </div>

        <!-- 13. Additional Legal Basis -->
        <div class="privacy-item">
            <h2>13. Additional Legal Basis</h2>
            <p>
                In addition, the processing may be based on your consent where you have expressly given that to us.
            </p>
        </div>

        <!-- 14. Sharing Personal Information -->
        <div class="privacy-item">
            <h2>14. Sharing Personal Information</h2>
            <p>
                We may share your Personal Information in the following circumstances:
            </p>
            <ul>
                <li>
                    We may share your Personal Information between the other The Law Chambers of U.J.Associates. entities on a confidential basis where required for the purpose of providing legal advice or other products or services and for administrative, billing, and other business purposes.
                </li>
                <li>
                    If we have collected your Personal Information in the course of providing legal services to any of our clients, we may disclose it to that client, and were permitted by law to others for the purpose of providing those services.
                </li>
                <li>
                    We may disclose your contact details on a confidential basis to third parties for the purposes of collecting your feedback on the firm’s service provision, to help us measure our performance, and to improve and promote our services;
                </li>
                <li>
                    We may share your Personal Information with companies providing services for money laundering checks, credit risk reduction, and other fraud and crime prevention purposes and companies providing similar services, including financial institutions, credit reference agencies, and regulatory bodies with whom such Personal Information is shared.
                </li>
                <li>
                    We may share your Personal Information with any third party to whom we assign or novate any of our rights or obligations.
                </li>
                <li>
                    We may share your Personal Information with courts, law enforcement authorities, regulators or attorneys, or other parties where it is reasonably necessary for The Law Chambers of U.J.Associates. to exercise or defend a legal or equitable claim, or for the purposes of a confidential alternative dispute resolution process.
                </li>
                <li>
                    We may also instruct service providers within or outside The Law Chambers of U.J.Associates., domestically or abroad, for example, shared service center, to process personal data for Permitted Purposes on our behalf and in accordance with our instructions only. The Law Chambers of U.J.Associates. will retain control over and will remain fully responsible for your Personal Information and will use appropriate safeguards as required by the applicable law to ensure the integrity and security of your Personal Information when engaging such service providers;
                </li>
                <li>
                    We may also use aggregated personal data and statistics for the purpose of monitoring website usage in order to help us develop our website and our services.
                </li>
            </ul>
        </div>

        <!-- 15. Additional Disclosures -->
        <div class="privacy-item">
            <h2>15. Additional Disclosures</h2>
            <p>
                Otherwise, we will only disclose your Personal Information when you direct us or give us permission, when we are required by applicable law or regulations or judicial or official request to do so, or as required to investigate actual or suspected fraudulent or criminal activities.
            </p>
        </div>

        <!-- 16. Third-Party Personal Information -->
        <div class="privacy-item">
            <h2>16. Third-Party Personal Information</h2>
            <p>
                If you provide Personal Information to us about someone else (such as one of your directors or employees, or someone with whom you have business dealings) you must ensure that you are entitled to disclose that Personal Information to us and that, without our taking any further steps, we may collect, use and disclose that Personal Information as described in this Policy. In particular, you must ensure the individual concerned is aware of the various matters detailed in this Policy, as those matters relate to that individual, including our identity, how to contact us, our purposes of collection, our personal data disclosure practices (including disclosure to overseas recipients), the individual’s right to obtain access to the Personal Information and make complaints about the handling of the Personal Information, and the consequences if the Personal Information is not provided (such as our inability to provide services).
            </p>
        </div>

        <!-- 17. Security Measures -->
        <div class="privacy-item">
            <h2>17. Security Measures</h2>
            <p>
                We will take appropriate technical and organizational measures to keep your Personal Information confidential and secure in accordance with our internal procedures covering the storage, disclosure of, and access to Personal Information. Personal Information may be kept on our personal data technology systems, those of our contractors, or in paper files.
            </p>
        </div>

        <!-- 18. Reasonable Security Practices -->
        <div class="privacy-item">
            <h2>18. Reasonable Security Practices</h2>
            <p>
                The Law Chambers of U.J.Associates., uses reasonable security measures, at the minimum those mandated under the IT Act, and read with Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, to safeguard and protect your Personal Information and Sensitive Personal Information. The Law Chambers of U.J.Associates. implement such measures, as stated above, to protect against unauthorized access to, and unlawful interception of, Personal Information and Sensitive Personal Information. Additionally, we have adopted measures to ensure that your Personal Information and Sensitive Personal Information are accessible to our employees or partner’s employees strictly on a need-to-know basis. You accept the inherent security implications of providing information over internet / cellular / data networks and will not hold The Law Chambers of U.J.Associates. responsible for any breach of security or the disclosure of Personal Information unless The Law Chambers of U.J.Associates. have been grossly and willingly negligent.
            </p>
        </div>

        <!-- 19. Data Storage -->
        <div class="privacy-item">
            <h2>19. Data Storage</h2>
            <p>
                Your Personal Information will primarily be stored in an electronic form. However, certain data can also be stored in physical form. The Law Chambers of U.J.Associates. may store, collect, process and use your Personal Information in countries other than the Republic of India but under full compliance with applicable laws. The Law Chambers of U.J.Associates. may agree with third parties (in or outside of India) to store, collect, and process your Personal Information and Sensitive Personal Information under full compliance with applicable laws.
            </p>
        </div>

        <!-- 20. Force Majeure -->
        <div class="privacy-item">
            <h2>20. Force Majeure</h2>
            <p>
                Notwithstanding anything contained in this Policy or elsewhere, The Law Chambers of U.J.Associates. shall not be held responsible for any loss, damage, or misuse of your Personal Information, if such loss, damage, or misuse is attributable to a Force Majeure Event. A “Force Majeure Event” shall mean any event that is beyond the reasonable control of The Law Chambers of U.J.Associates., and shall include, inter alia, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption.
            </p>
        </div>

        <!-- 21. Policy Modifications -->
        <div class="privacy-item">
            <h2>21. Policy Modifications</h2>
            <p>
                This Policy is subject to modification based on changes in the business, legal and regulatory requirements and will be made available to you online. We will make all reasonable efforts to communicate to you all significant changes to this Policy. You are encouraged to periodically visit this page to review the Policy and any changes thereto. In case you discontinue the use of the Website, it will not affect the applicability of the Policy to your prior uses of the Website.
            </p>
        </div>

        <!-- 22. Data Accuracy -->
        <div class="privacy-item">
            <h2>22. Data Accuracy</h2>
            <p>
                We strive hard to keep our records updated and accurate with your latest information. You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current, and updated and you have all the rights, permissions, and consent to provide such information or data to us.
            </p>
        </div>

        <!-- 23. Rights to Review and Modify Data -->
        <div class="privacy-item">
            <h2>23. Rights to Review and Modify Data</h2>
            <p>
                Based on the technical feasibility and requirements of applicable laws, we will provide you with the right to review, access, and modify the Personal Information or Sensitive Personal Information that we maintain about you. We may perform a verification before providing you access to this Personal Information. However, we are not responsible for the authenticity of the Sensitive Personal Information provided by you.
            </p>
        </div>

        <!-- 24. Withdrawal of Consent -->
        <div class="privacy-item">
            <h2>24. Withdrawal of Consent</h2>
            <p>
                Further, you have the right to withdraw the consent provided to us about your Sensitive Personal Information at any time in writing by sending an e-mail to us at 
                <a href="mailto:info&#64;ujassociates.com">info&#64;ujassociates.com</a>, by the terms of this Policy. However, please note that withdrawal of consent will not be retrospective in nature and shall be applied prospectively. In case you do not provide your information or consent for the usage of Sensitive Personal Information or subsequently withdraw your consent for the usage of the Sensitive Personal Information so collected, The Law Chambers of U.J.Associates. reserves the right to discontinue the services for which the said Sensitive Personal Information was sought.
            </p>
        </div>

        <!-- 25. Deletion of Sensitive Personal Information -->
        <div class="privacy-item">
            <h2>25. Deletion of Sensitive Personal Information</h2>
            <p>
                If you wish to delete the Sensitive Personal Information provided to The Law Chambers of U.J.Associates., you can always do so by sending a request to us at 
                <a href="mailto:info&#64;ujassociates.com">info&#64;ujassociates.com</a>.
            </p>
        </div>

        <!-- 26. Consequences of Data Correction or Deletion -->
        <div class="privacy-item">
            <h2>26. Consequences of Data Correction or Deletion</h2>
            <p>
                You may note that correction or deletion of certain information or data may lead to the cancellation of your registration with the Website or your access to certain features of the Website. You also agree and acknowledge that certain data or information cannot be corrected or deleted and/or is prohibited from being deleted under any applicable law or instead of law enforcement requests or under any judicial proceedings.
            </p>
        </div>

        <!-- 27. Commitment to Protect Personal Information -->
        <div class="privacy-item">
            <h2>27. Commitment to Protect Personal Information</h2>
            <p>
                We are committed to protecting your Personal Information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding the protection of your Personal Information, or any privacy-related feedback or concerns you may contact us at 
                <a href="mailto:info&#64;ujassociates.com">info&#64;ujassociates.com</a>.
            </p>
        </div>

        <!-- 28. Contact for Queries or Comments -->
        <div class="privacy-item">
            <h2>28. Contact for Queries or Comments</h2>
            <p>
                We welcome your views about our Website and our Policy. If you would like to contact us with any queries or comments, please send an email to 
                <a href="mailto:info&#64;ujassociates.com">info&#64;ujassociates.com</a>.
            </p>
        </div>
    </div>
</section>
