import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor(private router: Router) {}

  navigateToSection(sectionId: string) {
    this.router.navigate(['/practice-details'], { queryParams: { section: sectionId } });
  }


  ngOnInit(): void {
  }

}
