import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor( private router: Router) { }
  navigateToSection(sectionId: string) {
    this.router.navigate(['/practice-details'], { queryParams: { section: sectionId } });
  }



  ngOnInit(): void {
  }

}
