<div class="page-title-area page-title-area-three title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>About Our U.J Associates</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About Us</li>
                </ul>

                <!-- <div class="page-title-btn">
                    <a routerLink="/testimonial">ALL CERTIFICATES<i class="icofont-arrow-right"></i></a>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="help-area help-area-two help-area-four pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-one/5.jpg" alt="Help" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2> The Law Chambers of U.J. Associates</h2>
                        <p>
                            The Law Chambers of U.J. Associates is a registered
                            partnership firm established in the year 2012. Its
                            partners are Sri Kampalli Uday Kanth and Smt.
                            Kampalli Jaya Shree, Advocates.
                        </p>
                        <div >
                            <p>
                                U.J. Associates is a well-established and reputed
                                law firm, renowned for its proficiency in a wide
                                range of legal domains across India. The firm has
                                cultivated a strong presence across various judicial
                                forums, including lower courts, District and
                                Sessions Courts, the High Court of Telangana and
                                Andhra Pradesh, and the Supreme Court of India. The
                                team of legal experts at U.J. Associates is known
                                for its dedicated and client-focused approach,
                                offering comprehensive legal solutions in complex
                                and multifaceted legal matters.
                            </p>
                        </div>

                        <!-- <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div> -->

                    </div>
                </div>
            </div>
        </div>
<!-- 
        <div class="row align-items-center help-wrap">
            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>
                            Our Attorneys Always Provide The Excellent Service
                        </h2>
                        <p>
                            U.J. Associates is a well-established and reputed
                            law firm, renowned for its proficiency in a wide
                            range of legal domains across India. The firm has
                            cultivated a strong presence across various judicial
                            forums, including lower courts, District and
                            Sessions Courts, the High Court of Telangana and
                            Andhra Pradesh, and the Supreme Court of India. The
                            team of legal experts at U.J. Associates is known
                            for its dedicated and client-focused approach,
                            offering comprehensive legal solutions in complex
                            and multifaceted legal matters.
                        </p>
                      
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-two/6.jpg" alt="Help" />
                </div>
            </div>
        </div> -->

        <!-- <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape" />
        </div> -->
    </div>
</div>

<section class="expertise-area expertise-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>OUR EXPERTISE</span>
            <h2>Areas of Practice:</h2>
        </div>

        <div class="row align-items-start">
            <div class="col-md-6">
                <div class="expertise-item">
                    <ul>
                        <li
                            class="wow animate__animated animate__fadeInUp col-12 order-1"
                            data-wow-delay=".3s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>1. Lower Courts</h3>
                            <p>
                                The law chambers of U.J. Associates have a
                                well-established practice in various lower
                                courts, including civil courts, criminal courts,
                                and tribunals across India. The firm regularly
                                appears before Magistrate Courts, Sessions
                                Courts, and Consumer Dispute Redressal Forums.
                                Their presence in lower courts ensures that
                                clients receive prompt legal assistance at all
                                levels of the judicial hierarchy.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="expertise-item">
                    <ul>
                        <li
                            class="wow animate__animated animate__fadeInUp margins col-12 order-2 order-lg-1"
                            data-wow-delay=".3s" 
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>2. District and Sessions Courts</h3>
                            <p>
                                The firm is actively involved in representing
                                clients before District and Sessions Courts
                                across Telangana, Andhra Pradesh, and other
                                states in India. The team of advocates at U.J.
                                Associates is adept at handling both civil and
                                criminal matters, including trials, appeals, and
                                revisions in these courts. Their expertise also
                                extends to matters involving land acquisition,
                                compensation disputes, and other matters falling
                                under the jurisdiction of District Courts.<br>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row align-items-start">
        <div class="col-md-6">
            <div class="expertise-item">
                <ul>
                    <li
                            class="wow animate__animated animate__fadeInUp col-12 order-3 order-lg-2"
                            data-wow-delay=".5s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>
                                3. High Courts of Telangana and Andhra Pradesh
                            </h3>
                            <p>
                                U.J. Associates has an established presence in
                                the High Courts of Telangana and Andhra Pradesh.
                                The firm’s advocates regularly appear before
                                these courts in a wide array of matters,
                                including:
                                <br />
                                • Writ petitions challenging administrative
                                decisions and statutory orders.<br />
                                • Appeals from District and Sessions Courts on
                                both civil and criminal matters.<br />
                                • Complex constitutional challenges and public
                                interest litigations.<br />
                                • Service law disputes involving government
                                employees.<br />
                                • Corporate and commercial litigation, including
                                insolvency cases.
                            </p>
                        </li>
                </ul></div></div>

                <div class="col-md-6">
                    <div class="expertise-item">
                        <ul>
                            <li
                            class="wow animate__animated animate__fadeInUp col-12 order-4"
                            data-wow-delay=".3s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>4. Supreme Court of India</h3>
                            <p>
                                U.J. Associates has a dedicated team that
                                practices in the Supreme Court of India, the
                                apex court of the country. The firm’s lawyers
                                represent clients in:<br />
                                • Special Leave Petitions (SLPs) challenging
                                orders and judgments of the High Courts.<br />
                                • Constitutional challenges and interpretation
                                of statutes.<br />
                                • Appeals in civil and criminal matters of
                                significant legal importance.<br />
                                • Public Interest Litigations concerning
                                national policy and fundamental rights
                                issues.<br />
                                • Matters of corporate, commercial, and
                                arbitration disputes on appeal from High Courts.
                            </p>
                        </li>

                        </ul></div></div></div>

        <!-- <div class="row align-items-start">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <ul>
                        <li
                            class="wow animate__animated animate__fadeInUp col-12 order-1"
                            data-wow-delay=".3s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>1. Lower Courts</h3>
                            <p>
                                The law chambers of U.J. Associates have a
                                well-established practice in various lower
                                courts, including civil courts, criminal courts,
                                and tribunals across India. The firm regularly
                                appears before Magistrate Courts, Sessions
                                Courts, and Consumer Dispute Redressal Forums.
                                Their presence in lower courts ensures that
                                clients receive prompt legal assistance at all
                                levels of the judicial hierarchy.
                            </p>
                        </li>

                        <li
                            class="wow animate__animated animate__fadeInUp col-12 order-3 order-lg-2"
                            data-wow-delay=".5s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>
                                3. High Courts of Telangana and Andhra Pradesh
                            </h3>
                            <p>
                                U.J. Associates has an established presence in
                                the High Courts of Telangana and Andhra Pradesh.
                                The firm’s advocates regularly appear before
                                these courts in a wide array of matters,
                                including:
                                <br />
                                • Writ petitions challenging administrative
                                decisions and statutory orders.<br />
                                • Appeals from District and Sessions Courts on
                                both civil and criminal matters.<br />
                                • Complex constitutional challenges and public
                                interest litigations.<br />
                                • Service law disputes involving government
                                employees.<br />
                                • Corporate and commercial litigation, including
                                insolvency cases.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <ul>
                        <li
                            class="wow animate__animated animate__fadeInUp margins col-12 order-2 order-lg-1"
                            data-wow-delay=".3s" 
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>2. District and Sessions Courts</h3>
                            <p>
                                The firm is actively involved in representing
                                clients before District and Sessions Courts
                                across Telangana, Andhra Pradesh, and other
                                states in India. The team of advocates at U.J.
                                Associates is adept at handling both civil and
                                criminal matters, including trials, appeals, and
                                revisions in these courts. Their expertise also
                                extends to matters involving land acquisition,
                                compensation disputes, and other matters falling
                                under the jurisdiction of District Courts.<br>
                            </p>
                        </li>

                        <li
                            class="wow animate__animated animate__fadeInUp col-12 order-4"
                            data-wow-delay=".3s"
                        >
                            <div class="expertise-icon">
                                <i class="flaticon-money-bag"></i>
                                <img
                                    src="assets/img/home-one/11.png"
                                    alt="Shape"
                                />
                            </div>
                            <h3>4. Supreme Court of India</h3>
                            <p>
                                U.J. Associates has a dedicated team that
                                practices in the Supreme Court of India, the
                                apex court of the country. The firm’s lawyers
                                represent clients in:<br />
                                • Special Leave Petitions (SLPs) challenging
                                orders and judgments of the High Courts.<br />
                                • Constitutional challenges and interpretation
                                of statutes.<br />
                                • Appeals in civil and criminal matters of
                                significant legal importance.<br />
                                • Public Interest Litigations concerning
                                national policy and fundamental rights
                                issues.<br />
                                • Matters of corporate, commercial, and
                                arbitration disputes on appeal from High Courts.
                            </p>
                        </li>

                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</section>

<!-- <app-blog></app-blog> -->

<section class="practice-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Service we are specialize in:</h2>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Criminal Cases</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates handles criminal litigation,
                            representing clients in a variety of cases ranging
                            from minor offenses to serious crimes.
                        </li>
                        <li>
                            The firm assists clients with criminal defense, bail
                            applications, anticipatory bail, and other criminal
                            law-related proceedings.
                        </li>
                        <li>
                            Expert in effective court arguments, protecting  rights.
                           
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section1')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Child Rights</h3>
                    <ul class="text-start pb-19">
                        <li>
                            The firm is deeply engaged in protecting the rights
                            of children, dealing with cases of abuse, child
                            labor, trafficking, and also exploitation
                        </li>
                        <li>
                            U.J. Associates represents children in courts and
                            advocates for their welfare,   education , and<br>
                            protection under various child welfare laws.
                            
                        </li>
                        <br>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section6')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>



            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Writ Petitions</h3>
                    <ul class="text-start pb-19">
                        <li>
                            Specialization in filing and contesting writ
                            petitions in High Courts, challenging government
                            actions or seeking judicial intervention
                        </li>
                        <li>
                            U.J. Associates provides strategic advice on
                            constitutional rights, ensuring clients can
                            challenge unlawful government actions under Article
                            32 and Article 226 of the Indian Constitution
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section3')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Consumer Rights</h3>
                    <ul class="text-start">
                        <li>
                            The firm is proficient in consumer protection law,
                            representing clients before consumer forums for
                            disputes related to defective goods, services, and
                            unfair trade.
                        </li>
                        <li>
                            We assist in filing consumer complaints, claims for
                            damages, and seeking compensation for grievances
                            against manufacturers, service providers, or
                            sellers.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section8')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

       
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Service Matters</h3>
                    <ul class="text-start">
                        <li>
                            The firm is experienced in service law, dealing with
                            disputes related to government employees, such as
                            wrongful termination, promotions, transfers, and
                            some disciplinary actions.
                        </li>
                        <li>
                            We offer legal consultation on service rules, pay
                            scales, pension rights, and other employment-related<br>
                            issues
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section5')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>RTI</h3>
                    <ul class="text-start ">
                        <li>
                            U.J. Associates offers specialized services under
                            the RTI Act, assisting clients in drafting RTI
                            applications and contesting refusals of info
                            by public authorities
                        </li>
                        <li>
                            We provide legal representation in cases where there
                            is a denial or delay in providing information under
                            the RTI Act, ensuring transparency,
                            accountability
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section2')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

     
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Land Acquisition</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates specializes in cases related to land
                            acquisition and related disputes, including
                            compensation matters
                        </li>
                        <li>
                            The firm provides advisory services to clients
                            dealing with government acquisition of private
                            property and assists in claiming fair compensation
                            under the Land Acquisition Act, 2013
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section7')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Civil Cases</h3>
                    <ul class="text-start">
                        <li>
                            The firm has strong expertise in civil litigation,
                            representing individuals and businesses in disputes
                            related to the property,  contracts,  torts, <br> family law,
                            and <br> inheritance.
                        </li>
                        <li>
                            We handle injunctions, partitions, possession suits,
                            and other civil remedies in the courts across the Telangana  state.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section4')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>


   
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Human Rights</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates is actively involved in human rights
                            advocacy, taking on cases that focus on violations
                            of fundamental rights
                        </li>
                        <li>
                            We represent individuals or groups in cases
                            involving police brutality, discrimination, and
                            other abuses of power, working to uphold the dignity
                            and rights of individuals.
                        </li>
                        <br>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section9')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Debt Recovery Tribunals</h3>
                    <ul class="text-start">
                        <li>
                            The firm has a proven track record in handling debt
                            recovery matters, representing banks, financial
                            institutions, and borrowers in cases before the Debt
                            Recovery Tribunal and Appellate (DRT) and (DRAT).
                        </li>
                        <li>
                            U.J. Associates provides strategic legal counsel for
                            loan recovery, enforcement of securities, and other
                            related disputes under the  SARFAESI Act.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section10')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Comprehensive and Strategic Legal Solutions</h3>
                    <ul class="text-start pb-19">
                        <li>
                            The firm is renowned for offering end-to-end legal
                            solutions, from legal advisory to litigation
                            services, across various domains
                        </li>
                        <li>
                            Their strategic approach combines practical legal
                            knowledge with a deep understanding of client needs,
                            ensuring that every case is handled meticulously,
                            considering the best possible legal strategies.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section11')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Tax Laws</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates provides expert legal advice on tax
                            matters, including income tax,
                             GST, and the other direct
                            and indirect taxes
                        </li>
                        <li>
                            We assist clients in tax planning, tax litigation,
                            and defending cases of tax evasion or disputes with
                            the tax authorities
                        </li>
                        <li>The firm boasts an impressive track record in defending the rights of its clients against improper  tax  and assessments, ensuring that justice is served at every legal level.</li>
                    </ul>
       
                    <a href="javascript:void(0)" (click)="navigateToSection('section12')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Additional Specialized Legal Areas</h3>
                    <ul class="text-start">
                        <li>
                            Corporate Law
                        </li>
                        <li>
                            Environmental Law
                        </li>
                        <li>
                            Intellectual Property Rights (IPR)
                        </li>
                      
                    </ul>
               
                    <a href="javascript:void(0)" (click)="navigateToSection('section13')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="about-area pt-100">
    <div class="container">
        <div class="row"> -->
<!-- <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-video-wrap">
                        <div class="about-video">
                            <img src="assets/img/about/2.jpg" alt="About">
                        </div>

                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                    </div>

                    <div class="about-content">
                        <h2>Our skills makes us famous</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </div>
                </div>
            </div> -->

<!-- <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-information">
                        <h2><span>21 feb 1998,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>10 March 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>9 Nov 2000,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <div class="about-information">
                        <h2><span>25 Jan 2010,</span> We started our law firm</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices </p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
