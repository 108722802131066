import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactForm } from '../pages/contact/types';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
    CONSTANTS = {
        contactAPIURL : environment.contactApiBaseUrl + 'api/V1/contact-form',
    }

  constructor(private http: HttpClient) {}

  submitContactForm(contactData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<ContactForm>(this.CONSTANTS.contactAPIURL, contactData, { headers });
  }
}