<!-- <div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Case Studies Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Money Loundering</li>
                </ul>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="case-details-img pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="case-details-item">
                    <img src="assets/img/case-details/1.jpg" alt="Case">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="case-details-item">
                    <h3>About: Kampalli Uday Kanth</h3>
<p>

    Mr. Kampalli Uday Kanth began his legal career under the mentorship of Shri V. Venkat Kumar, Senior Advocate at the Andhra Pradesh High Court. In his early years, he managed his mentor’s office while actively attending cases at the High Court and other courts in Hyderabad and Ranga Reddy District. Simultaneously, he collaborated with Shri R.D. Venkat Rao, Retired Additional Director of Prosecution and Legal Advisor to the Police Commissioner of Cyberabad, gaining substantial experience in criminal law practice.</p>
    
    <p>He also worked closely with Shri Ramesh Babu, Senior Advocate and Panel Advocate for several nationalized banks in Hyderabad. Over time, Mr. Uday Kanth was empanelled as a Legal Advisor to major construction companies in Hyderabad, handling various legal matters for these entities. </p>
    
        <p> After accumulating extensive experience under these esteemed legal professionals, Mr. Uday Kanth embarked on his independent legal practice, where he has been successfully handling a wide range of legal cases.</p>
    
            <p>In 2013, Mr. Uday Kanth was appointed as Counsel for the Road Transport Authority (RTA) in Ranga Reddy District, representing the transport department in various legal matters.</p>
    
                <p>In 2015, he was appointed as the Convener of the Legal Cell for the Telangana Pradesh Congress Committee (TPCC), under the chairmanship of Shri C. Damodar Reddy, Senior Advocate at the High Court of Telangana. In 2018, he was further appointed as Vice-Chairman of the Legal, Human Rights, and RTI Department for the TPCC. Alongside his legal practice, Mr. Uday Kanth remains an active figure within the Indian National Congress Party, where his legal expertise has been instrumental.</p>
    
                    <p>Beyond traditional legal practice, Mr. Uday Kanth has handled several high-profile cases, excelling in civil, criminal, and corporate law. His exposure to various legal fields, including constitutional, administrative, and banking law, has enriched his comprehensive legal expertise.</p>
    
                        <p>He is also recognized for his proficiency in arbitration and alternative dispute resolution (ADR), having successfully represented clients in arbitration tribunals to resolve complex commercial disputes. His role as a legal advisor to major construction companies has also deepened his expertise in navigating legal challenges related to the infrastructure sector, including contract negotiations, regulatory compliance, and dispute resolution.</p>
    
                            <p>In addition to his legal practice, Mr. Uday Kanth has made significant contributions to legal education. He has mentored junior advocates, organized legal awareness camps, and participated in numerous legal seminars and workshops. His involvement in the TPCC has enabled him to contribute to legal reforms and human rights advocacy, further solidifying his role as a multifaceted legal professional.</p>
    
                                <p>Moreover, his specialization in handling Right to Information (RTI) applications and human rights cases has made him a sought-after expert in public policy, government transparency, and citizen rights. His contributions in these areas have had a lasting impact on both the legal community and society at large.</p>                  
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="case-details-tab p-t-6">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="case-details-tab-item about">
                    <h4 class="text-white">About: Kampalli Uday Kanth</h4>
                    <!-- 
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Challenge</a></li>

                        <li class="nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Solution</a></li>

                        <li class="nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Result</a></li>
                    </ul> -->

                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <p>
                                Mr. Kampalli Uday Kanth began his legal career
                                under the mentorship of Shri V. Venkat Kumar,
                                Senior Advocate at the Andhra Pradesh High
                                Court. In his early years, he managed his
                                mentor’s office while actively attending cases
                                at the High Court and other courts in Hyderabad
                                and Ranga Reddy District. Simultaneously, he
                                collaborated with Shri R.D. Venkat Rao, Retired
                                Additional Director of Prosecution and Legal
                                Advisor to the Police Commissioner of Cyberabad,
                                gaining substantial experience in criminal law
                                practice.
                            </p>

                            <p class="m-t-15">
                                He also worked closely with Shri Ramesh Babu,
                                Senior Advocate and Panel Advocate for several
                                nationalized banks in Hyderabad. Over time, Mr.
                                Uday Kanth was empanelled as a Legal Advisor to
                                major construction companies in Hyderabad,
                                handling various legal matters for these
                                entities.
                            </p>

                            <p class="m-t-15">
                                After accumulating extensive experience under
                                these esteemed legal professionals, Mr. Uday
                                Kanth embarked on his independent legal
                                practice, where he has been successfully
                                handling a wide range of legal cases.
                            </p>

                            <p class="m-t-15">
                                In 2013, Mr. Uday Kanth was appointed as Counsel
                                for the Road Transport Authority (RTA) in Ranga
                                Reddy District, representing the transport
                                department in various legal matters.
                            </p>

                            <p class="m-t-15">
                                In 2015, he was appointed as the Convener of the
                                Legal Cell for the Telangana Pradesh Congress
                                Committee (TPCC), under the chairmanship of Shri
                                C. Damodar Reddy, Senior Advocate at the High
                                Court of Telangana. In 2018, he was further
                                appointed as Vice-Chairman of the Legal, Human
                                Rights, and RTI Department for the TPCC.
                                Alongside his legal practice, Mr. Uday Kanth
                                remains an active figure within the Indian
                                National Congress Party, where his legal
                                expertise has been instrumental.
                            </p>

                        </div>
                        <!-- 
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <h3>Now a days criminality is at the top amongst college and university going students.</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <h3>A criminal case, in common law jurisdictions, begins when a person suspected of a crime.</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="case-details-tab-item profile">
                    <div class="case-details-tab-img">
                        <img src="assets/img/profile.jpg" alt="Case" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row about-text about">
            <p>
                Beyond traditional legal practice, Mr. Uday
                Kanth has handled several high-profile cases,
                excelling in civil, criminal, and corporate law.
                His exposure to various legal fields, including
                constitutional, administrative, and banking law,
                has enriched his comprehensive legal expertise.
            </p>

            <p>
                He is also recognized for his proficiency in
                arbitration and alternative dispute resolution
                (ADR), having successfully represented clients
                in arbitration tribunals to resolve complex
                commercial disputes. His role as a legal advisor
                to major construction companies has also
                deepened his expertise in navigating legal
                challenges related to the infrastructure sector,
                including contract negotiations, regulatory
                compliance, and dispute resolution.
            </p>

            <p>
                In addition to his legal practice, Mr. Uday
                Kanth has made significant contributions to
                legal education. He has mentored junior
                advocates, organized legal awareness camps, and
                participated in numerous legal seminars and
                workshops. His involvement in the TPCC has
                enabled him to contribute to legal reforms and
                human rights advocacy, further solidifying his
                role as a multifaceted legal professional.
            </p>

            <p class="pb-3">
                Moreover, his specialization in handling Right
                to Information (RTI) applications and human
                rights cases has made him a sought-after expert
                in public policy, government transparency, and
                citizen rights. His contributions in these areas
                have had a lasting impact on both the legal
                community and society at large.
            </p>
        </div>
    </div>
</div>
<!-- 
<section class="portfolio-area portfolio-area-three pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Related Cases</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                        <p>10 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="/case-study-details">Property Problem</a></h3>
                        <p>11 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="/case-study-details">Money Loundering</a></h3>
                        <p>12 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/4.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Cyber</span>
                        <h3><a routerLink="/case-study-details">Cyber Crime Case</a></h3>
                        <p>13 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/5.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Hack</span>
                        <h3><a routerLink="/case-study-details">Bank Hack Case</a></h3>
                        <p>14 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/6.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Politics</span>
                        <h3><a routerLink="/case-study-details">Political Case</a></h3>
                        <p>15 Feb 2024</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->