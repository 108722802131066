<!-- <div class="home-slider-area">
    <div class="home-slider owl-theme owl-carousel">
        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>It’s Our Pride To Fight For Your Dream</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-one" src="assets/img/home-two/home-slider/2.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>Our attorney’s main goal is to challenge  for  your privileges</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-two" src="assets/img/home-two/home-slider/3.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="slider-text">
                                    <h1>Our Attorney’s are Always Prepared to Oppose For You</h1>
                                    <p>FIGHTING FOR YOUR DREAM</p>
                                    <a routerLink="/">FREE CONSULTATION <i class="icofont-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                                <div class="slider-shape">
                                    <img class="s-s-three" src="assets/img/home-two/home-slider/4.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="help-area help-area-three pb-70 p-t-auto">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="help-item help-left">
                    <img src="assets/img/home-one/bg1.jpg" alt="Help" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>
                            U.J.Associates Provide Professional Attorneys to
                            Assist You
                        </h2>
                        <p>
                            Provides a broad range of specialized professional
                            legal services Led by <span class="bold">Shri Kampalli Uday Kanth</span>
                            & <span class="bold"> Smt. Kampalli Jaya Shree</span>, along with their team of
                            experts. The firm offers its expertise across
                            multiple fields
                        </p>

                        <!-- <h4 class="fw-bold">U.J. Associates</h4> -->
                           <h4 class="fw-bold"> Your Trusted Legal Partners Since 2012</h4>
                            
                           <p>At U.J. Associates, we are more than just a law firm—we are advocates for justice, providing expert legal solutions across a broad spectrum of civil, criminal, and constitutional matters. With a strong presence in courts at all levels, including the High Courts and the Supreme Court of India, our team is renowned for its client-centered approach and proven results. From resolving complex disputes to protecting your rights, our commitment to excellence ensures you receive personalized, strategic, and effective legal representation.
                            </p><p>
                            Discover how our expertise in Civil Law, Criminal Defense, Corporate Law, and Constitutional Litigation can serve you—experience the U.J. Associates difference today.</p>

                        <!-- <div class="help-inner-left">
                            <ul>
                                <li>
                                    <i class="flaticon-checkmark"></i> Browse
                                    Our Website
                                </li>
                                <li>
                                    <i class="flaticon-checkmark"></i> Choose
                                    Services
                                </li>
                            </ul>
                        </div> -->
<!-- 
                        <div class="help-inner-right">
                            <ul>
                                <li>
                                    <i class="flaticon-checkmark"></i> Quick
                                    Reply
                                </li>
                                <li>
                                    <i class="flaticon-checkmark"></i> Best
                                    Performances
                                </li>
                            </ul>
                        </div> -->

                        <!-- <div class="help-signature">
                            <img src="assets/img/home-one/4.png" alt="Signature">
                        </div> -->

                        <!-- <a class="cmn-btn mt-5" routerLink="/practice">Learn More</a> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="help-shape">
            <img src="assets/img/home-one/6.png" alt="Shape" />
        </div> -->
    </div>
</div>
<!-- 
<app-case-study-details></app-case-study-details> -->

<!-- <section class="practice-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Service we are specialize in:</h2>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Criminal Cases</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates handles criminal litigation,
                            representing clients in a variety of cases ranging
                            from minor offenses to serious crimes.
                        </li>
                        <li>
                            The firm assists clients with criminal defense, bail
                            applications, anticipatory bail, and other criminal
                            law-related proceedings.
                        </li>
                        <li>
                            Expertise in presenting effective arguments in
                            courts, ensuring the protection of clients' rights
                            under the law.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section1')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Child Rights</h3>
                    <ul class="text-start pb-19">
                        <li>
                            The firm is deeply engaged in protecting the rights
                            of children, dealing with cases of abuse, child
                            labor, trafficking, and exploitation
                        </li>
                        <li>
                            U.J. Associates represents children in courts and
                            advocates for their welfare, education, and
                            protection under various child welfare laws.
                        </li>
                        <br>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section6')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>



            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Writ Petitions</h3>
                    <ul class="text-start pb-19">
                        <li>
                            Specialization in filing and contesting writ
                            petitions in High Courts, challenging government
                            actions or seeking judicial intervention
                        </li>
                        <li>
                            U.J. Associates provides strategic advice on
                            constitutional rights, ensuring clients can
                            challenge unlawful government actions under Article
                            32 and Article 226 of the Indian Constitution
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section3')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Consumer Rights</h3>
                    <ul class="text-start">
                        <li>
                            The firm is proficient in consumer protection law,
                            representing clients before consumer forums for
                            disputes related to defective goods, services, and
                            unfair trade.
                        </li>
                        <li>
                            We assist in filing consumer complaints, claims for
                            damages, and seeking compensation for grievances
                            against manufacturers, service providers, or
                            sellers.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section8')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

       
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Service Matters</h3>
                    <ul class="text-start">
                        <li>
                            The firm is experienced in service law, dealing with
                            disputes related to government employees, such as
                            wrongful termination, promotions, transfers, and
                            disciplinary actions.
                        </li>
                        <li>
                            We offer legal consultation on service rules, pay
                            scales, pension rights, and other employment-related
                            issues
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section5')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Right to Information (RTI) Act</h3>
                    <ul class="text-start ">
                        <li>
                            U.J. Associates offers specialized services under
                            the RTI Act, assisting clients in drafting RTI
                            applications and contesting refusals of information
                            by public authorities
                        </li>
                        <li>
                            We provide legal representation in cases where there
                            is a denial or delay in providing information under
                            the RTI Act, ensuring transparency and
                            accountability
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section2')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

     
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>Land Acquisition</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates specializes in cases related to land
                            acquisition and related disputes, including
                            compensation matters
                        </li>
                        <li>
                            The firm provides advisory services to clients
                            dealing with government acquisition of private
                            property and assists in claiming fair compensation
                            under the Land Acquisition Act, 2013
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section7')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Civil Cases</h3>
                    <ul class="text-start">
                        <li>
                            The firm has strong expertise in civil litigation,
                            representing individuals and businesses in disputes
                            related to the property,  contracts,  torts,  family law,
                            and inheritance.
                        </li>
                        <li>
                            We handle injunctions, partitions, possession suits,
                            and other civil remedies in courts across Telangana
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section4')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>


   
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Human Rights</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates is actively involved in human rights
                            advocacy, taking on cases that focus on violations
                            of fundamental rights
                        </li>
                        <li>
                            We represent individuals or groups in cases
                            involving police brutality, discrimination, and
                            other abuses of power, working to uphold the dignity
                            and rights of individuals.
                        </li>
                        <br>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section9')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Debt Recovery Tribunals</h3>
                    <ul class="text-start">
                        <li>
                            The firm has a proven track record in handling debt
                            recovery matters, representing banks, financial
                            institutions, and borrowers in cases before the Debt
                            Recovery Tribunal (DRT) and Debt Recovery Appellate
                            Tribunal (DRAT).
                        </li>
                        <li>
                            U.J. Associates provides strategic legal counsel for
                            loan recovery, enforcement of securities, and other
                            related disputes under the  SARFAESI Act.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section10')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Comprehensive and Strategic Legal Solutions</h3>
                    <ul class="text-start pb-19">
                        <li>
                            The firm is renowned for offering end-to-end legal
                            solutions, from legal advisory to litigation
                            services, across various domains
                        </li>
                        <li>
                            Their strategic approach combines practical legal
                            knowledge with a deep understanding of client needs,
                            ensuring that every case is handled meticulously,
                            considering the best possible legal strategies.
                        </li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection('section11')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Tax Laws</h3>
                    <ul class="text-start">
                        <li>
                            U.J. Associates provides expert legal advice on tax
                            matters, including income tax,<br>
                             GST, and the other direct
                            and <br> indirect taxes
                        </li>
                        <li>
                            We assist clients in <br> tax planning, <br>tax litigation,<br>
                            and defending cases of <br>tax evasion or disputes with
                            the tax authorities
                        </li>
                    </ul>
       
                    <a href="javascript:void(0)" (click)="navigateToSection('section12')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Additional Specialized Legal Areas</h3>
                    <ul class="text-start">
                        <li>
                            Corporate Law
                        </li>
                        <li>
                            Environmental Law
                        </li>
                        <li>
                            Intellectual Property Rights (IPR)
                        </li>
                      
                    </ul>
               
                    <a href="javascript:void(0)" (click)="navigateToSection('section13')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="expertise-area expertise-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title text-start">
                    <span>OUR EXPERTISE</span>
                    <h2>Law Firm Devoted To Our Clients</h2>
                </div>

               <div class="expertise-item">
                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-icon">
                                <i class="flaticon-experience"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Experience</h3>
                            <p>Team of U.J.Associates are exceptionally skilled, realize their client’s condition, help them to achieve their motive.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div class="expertise-icon">
                                <i class="flaticon-lawyer"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Skilled Attorney</h3>
                            <p>Our attorneys are creative and knowledgeable and support clients by finding numerous solutions to win.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-icon">
                                <i class="flaticon-balance"></i>
                                <img src="assets/img/home-one/11.png" alt="Shape">
                            </div>
                            <h3>Legal Proces</h3>
                            <p>We manage all valid papers and paperwork and continue to enter our path lawfully.</p>
                        </li>
                    </ul>
               </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-image">
                    <img src="assets/img/home-one/10.png" alt="Expertise">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>PORTFOLIO</span>
            <h2>Check Out Our Popular Case Studies.</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="case-study-details/">Critical Murder Case</a></h3>
                        <p>15 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="case-study-details/">Children Rights</a></h3>
                        <p>16 Feb 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="portfolio-item">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">
                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="case-study-details/">Money Loundering</a></h3>
                        <p>17 Feb 2024</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="cmn-btn" routerLink="/case-study">See More</a>
        </div>
    </div>
</section> -->

<!-- <section class="team-area">
    <div class="container">
        <div class="section-title">
            <span>TEAM MEMBER</span>
            <h2>Meet Our Expert Attorneys</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/1.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Jerry Hudson</a></h3>
                        <span>Family Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/2.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Juho Hudson</a></h3>
                        <span>Criminal Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/3.jpg" alt="Team">
                    
                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Sarah Se</a></h3>
                        <span>Divorce Consultant</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <img src="assets/img/home-one/team/4.jpg" alt="Team">

                    <div class="team-inner">
                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                        <h3><a routerLink="/attorney-details">Attor. Aikin Ward</a></h3>
                        <span>Business Consultant</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <app-blog></app-blog> -->

<!-- Legal domains -->




<section class="practice-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Expertise in Various Legal Domains</h2>
        </div>
        <div class="row">
            <div *ngFor="let area of legalDomains" class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i [class]="area.icon"></i>
                    </div>
                    <h3>{{ area.title }}</h3>
                    <p>{{ area.description }}</p>
                    <ul>
                        <li *ngFor="let point of area.points">{{ point }}</li>
                    </ul>
                    <a href="javascript:void(0)" (click)="navigateToSection(area.readMoreLink)">Read More</a>
                    <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape"/>
                    <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape"/>
                </div>
            </div>
            
            <!-- <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>

                    </div>
                    <h3>Criminal Law</h3>
                    <p>
                        The firm has a strong criminal law practice,
                        representing clients in criminal cases ranging from
                        minor offences to major crimes. With in-depth
                        knowledge of criminal procedure and substantive
                        criminal law, U.J. Associates provides robust
                        representation in: <br>
                        • Bail applications and
                        anticipatory bail. <br>
                        • Criminal trials involving
                        offences under the Indian Penal Code (IPC). <br>
                        •
                        White-collar crimes such as fraud, forgery, and
                        embezzlement.<br>
                         • Cyber crimes and offences under
                        special statutes like the Prevention of Corruption
                        Act. <br>
                        • Appealss in criminal matters
                       
                    </p>
                    <a href="javascript:void(0)">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                   
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-law"></i>
                    </div>
                    <h3>Civil Law</h3>
                    <p>
                        U.J. Associates has extensive experience in civil
                        litigation and advisory services. The firm handles a
                        variety of civil disputes, including property
                        disputes, family law matters, contractual disputes,
                        and tort claims. The lawyers at the firm are skilled
                        in representing clients in matters involving:
                        <br />
                        • Partition suits and property ownership
                        disputes.
                         <br />
                         • Contractual agreements, breach of
                        contract, and specific performance.
                         <br />
                         •Matrimonial issues, including divorce, maintenance,
                        and child custody. <br />
                        • Matters relating to
                        wills, inheritance, and succession. <br />
                       
                    </p>
                    <a href="javascript:void(0)">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Corporate and Commercial Law</h3>
                    <p>
                        U.J. Associates provides comprehensive legal
                        solutions to corporate clients, advising on a range
                        of business transactions and commercial disputes.
                        Their expertise the spans across: <br>
                        • Company law,
                        including formation, mergers, acquisitions, and
                        corporate governance.<br>
                         • Insolvency and bankruptcy
                        cases under the Insolvency and Bankruptcy Code
                        (IBC).<br>
                         • Commercial contract drafting, review, and
                        litigation. <br>
                        • Arbitration and dispute resolution in
                        commercial conflicts.<br>
                       
                    </p>
                    <a href="javascript:void(0)" (click)="navigateToSection('section14')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-vulnerability"></i>
                    </div>
                    <h3>Constitutional Law</h3>
                    <p>
                        The firm has considerable experience in handling
                        matters relating to constitutional law, including
                        public interest litigation (PIL), writ petitions,
                        and fundamental rights violations. U.J. Associates
                        frequently represents clients in:<br>
                         • Writ petitions
                        (under Articles 32 and 226) concerning the violation
                        of fundamental rights.<br>
                         • Challenges to government
                        actions, policies, and legislation.<br>
                         • Public
                        Interest Litigation in matters affecting public
                        welfare and policy. <br>
                        • Service law disputes involving
                        government employees.
                    </p>
                    <a href="javascript:void(0)"  (click)="navigateToSection('section15')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>

       
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-mortarboard"></i>
                    </div>
                    <h3>Labour and Employment Law</h3>
                    <p>
                        The firm has a robust practice in labour and
                        employment law, representing both employers and
                        employees in industrial disputes, wrongful
                        termination cases, and employee benefits litigation.
                        Key areas of practice <br>include:<br>
                         • Drafting and
                        enforcing employment contracts.<br>
                         • Handling disputes
                        related to wages, termination, and compensation.<br>
                         • Industrial disputes and strikes under the Industrial
                        Disputes Act.<br>
                         • Labour law compliance and advisory
                        services.
                    </p>
                    <a href="javascript:void(0)" (click)="navigateToSection('section16')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-family"></i>
                    </div>
                    <h3>Family Law</h3>
                    <p>
                        U.J. Associates provides expert legal advice and
                        representation in family the law matters, offering
                        compassionate yet firm representation in cases
                       <br> involving: <br>
                        • Divorce and judicial separation.<br>
                         • Custody battles and child support disputes.<br>
                         • Domestic violence cases under the Protection of
                        Women from Domestic Violence Act.<br>
                         • Dowry harassment
                        cases under the Dowry Prohibition Act and Section
                        498A of the IPC.<br>
                         • Maintenance and alimony<br>
                        petitions.
                    </p>
                    <a href="javascript:void(0)" (click)="navigateToSection('section17')">Read More</a>
                    <img
                    class="practice-shape-one"
                    src="assets/img/home-one/7.png"
                    alt="Shape"
                />
                <img
                    class="practice-shape-two"
                    src="assets/img/home-one/8.png"
                    alt="Shape"
                />
                </div>
            </div>

     
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-auction"></i>
                    </div>
                    <h3>ADR</h3>
                        <p>
                            U.J. Associates excels in arbitration and ADR
                            methods, providing cost-effective and time-saving
                            dispute resolution strategies. The firm represents
                            clients in: <br>
                            • Domestic and international arbitration
                            proceedings.<br>
                             • Mediation and conciliation of
                            disputes in commercial and family law matters.<br>
                             • Enforcing and challenging arbitral awards under the
                            Arbitration and Conciliation Act.
                        </p>
                    <a href="javascript:void(0)" (click)="navigateToSection('section18')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i class="flaticon-inheritance"></i>
                    </div>
                    <h3>Intellectual Property Law</h3>
                    <p>
                        The lawyers at U.J. Associates provide specialized
                        services in intellectual property law, and also assisting
                        clients with:<br>
                         • Trademark registration, protection,
                        and infringement cases.<br>
                         • Patent filing, and litigation. <br>
                        • Copyright
                        infringement disputes and litigation.<br>
                         • Licensing
                        agreements and IP-related commercial transactions.
                    </p>
                    <a href="javascript:void(0)" (click)="navigateToSection('section19')">Read More</a>
                    <img
                        class="practice-shape-one"
                        src="assets/img/home-one/7.png"
                        alt="Shape"
                    />
                    <img
                        class="practice-shape-two"
                        src="assets/img/home-one/8.png"
                        alt="Shape"
                    />
                </div> -->
            </div>
        </div>
</section>

<div class="contact-form pb-100">
    <!-- <div class="counter-area counter-area-two">
        <div class="container">
            <div class="row counter-bg">
                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-judge"></i>
                        <div class="counter-inner">
                            <h3 class="counter">18</h3>
                            <p>Attorneys</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-auction"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">99</span>%</h3>
                            <p>Case Won</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-medal"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">100</span>%</h3>
                            <p>Legal Way</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-6 col-lg-3">
                    <div class="counter-item">
                        <i class="icofont-law-order"></i>
                        <div class="counter-inner">
                            <h3><span class="counter">10</span>K</h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container-fluid">
        <form
            [formGroup]="contactForm"
            class="p-3"
            (ngSubmit)="onSubmit()"
            id="contactForm"
        >
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="name"
                            class="form-control"
                            placeholder="Your Full Name"
                        />
                        <div
                            *ngIf="f.name.touched && f.name.errors"
                            class="text-danger"
                        >
                            <small *ngIf="f.name.errors.required"
                                >Name is required.</small
                            >
                            <small *ngIf="f.name.errors.minlength"
                                >Name must be at least 3 characters long.</small
                            >
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="phone_number"
                            class="form-control"
                            placeholder="Your Phone"
                        />
                        <div
                            *ngIf="
                                f.phone_number.touched && f.phone_number.errors
                            "
                            class="text-danger"
                        >
                            <small *ngIf="f.phone_number.errors.required"
                                >Phone number is required.</small
                            >
                            <small *ngIf="f.phone_number.errors.pattern"
                                >Enter a valid 10-digit phone number.</small
                            >
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input
                            type="email"
                            formControlName="email"
                            class="form-control"
                            placeholder="Your Email"
                        />
                        <div
                            *ngIf="f.email.touched && f.email.errors"
                            class="text-danger"
                        >
                            <small *ngIf="f.email.errors.required"
                                >Email is required.</small
                            >
                            <small *ngIf="f.email.errors.email"
                                >Enter a valid email address.</small
                            >
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="msg_subject"
                            class="form-control"
                            placeholder="Subject"
                        />
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea
                            formControlName="message"
                            class="form-control"
                            cols="30"
                            rows="8"
                            placeholder="Case Description"
                        ></textarea>
                        <div
                            *ngIf="f.message.touched && f.message.errors"
                            class="text-danger"
                        >
                            <small *ngIf="f.message.errors.required"
                                >Case description is required.</small
                            >
                            <small *ngIf="f.message.errors.minlength"
                                >Case description must be at least 10 characters
                                long.</small
                            >
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button
                            type="submit"
                            class="contact-btn"
                            [disabled]="contactForm.invalid"
                        >
                            Submit Case
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- <section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <span>BLOG</span>
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="blog-slider owl-theme owl-carousel">
            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3><a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/1.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Rights Case</span>
                    <h3><a routerLink="/blog-details">Justice May For You If You Are Innocent</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/2.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Business Case</span>
                    <h3>
                        <a routerLink="/blog-details">By Whom Your Business Is Being Loss?</a></h3><ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <div class="blog-item">
                <a routerLink="/blog-details">
                    <img src="assets/img/home-one/blog/3.jpg" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>Criminal Case</span>
                    <h3><a routerLink="/blog-details">Who Can The Victim A Sue After A Car Accident?</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                        <li><i class="icofont-user-alt-7"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a class="blog-link" routerLink="/blog-details">Read More <i class="icofont-simple-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
