<footer>
    <!-- <div class="newsletter-area">
        <div class="container">
            <img src="assets/img/home-one/newsletter.png" alt="Shape">
            <h2>Subscribe Newsletter</h2>

            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                <button class="btn contact-btn" type="submit">Subscribe</button>
            </form>
        </div>
    </div> -->

    <div class="container">
        <div class="row pt-5">
            <div class="col-sm-4 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <!-- <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a> -->
                        <div class="footer-service">
                            <a class="navbar-brand" routerLink="/">
                                <img
                                    src="assets/img/uj-logo.png"
                                    alt="Logo"
                                    class="logo-img"
                                />
                            </a>
                        </div>
                        <p>
                            Our Squad of Attorneys are always here to provide
                            you the best and efficient service. Still, if you
                            want to know more about us and our firm, you can
                            connect us through social media.
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.youtube.com/@kampalliudaykanth7000  
                                "
                                    target="_blank"
                                    ><i class="icofont-youtube-play"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/kampalliudaykanth?mibextid=LQQJ4d  
                                "
                                    target="_blank"
                                    ><i class="icofont-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/uday.legalcelltpcc/
                                "
                                    target="_blank"
                                    ><i class="icofont-instagram"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="http://linkedin.com/in/kampalli-uday-kanth-b08a731aa"
                                    target="_blank"
                                    ><i class="icofont-linkedin"></i
                                ></a>
                            </li>

                            <li>
                                <a href="https://twitter.com/KampalliUday"
                                    ><i
                                        class="icofont-twitter"
                                        target="_blank"
                                    ></i
                                ></a>
                            </li>
                            <li class="pt-2">
                                <a
                                    href="https://wa.me/919030678999"
                                    target="_blank"
                                >
                                    <i class="icofont-whatsapp"></i> Chat with
                                    us on WhatsApp
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-4">
                <div class="footer-item">
                    <div class="footer-service text-decoration-none">
                        <h3>Expertise in Various Legal Domains:</h3>

                        <ul>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section1')"
                                    ><i class="icofont-simple-right"></i>
                                    Criminal Cases</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section2')"
                                    ><i class="icofont-simple-right"></i> Right
                                    to Information (RTI) Act</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section3')"
                                    ><i class="icofont-simple-right"></i> Writ
                                    Petitions
                                </a>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section4')"
                                    ><i class="icofont-simple-right"></i> Civil
                                    Cases</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section5')"
                                    ><i class="icofont-simple-right"></i>
                                    Service Matters</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section6')"
                                    ><i class="icofont-simple-right"></i> Child
                                    Rights</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section7')"
                                    ><i class="icofont-simple-right"></i> Land
                                    Acquisition</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section8')"
                                    ><i class="icofont-simple-right"></i>
                                    Consumer Rights</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section9')"
                                    ><i class="icofont-simple-right"></i> Human
                                    Rights</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section10')"
                                    ><i class="icofont-simple-right"></i> Debt
                                    Recovery Tribunals</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section11')"
                                    ><i class="icofont-simple-right"></i>
                                    Comprehensive and Strategic Legal
                                    Solutions</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section12')"
                                    ><i class="icofont-simple-right"></i> Tax
                                    Laws</a
                                >
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    (click)="navigateToSection('section13')"
                                    ><i class="icofont-simple-right"></i>
                                    Additional Specialized Legal Areas</a
                                >
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section14')"
                                ><i class="icofont-simple-right"></i>
                                    Criminal Law
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"
                                 (click)="navigateToSection('section15')"
                                 ><i class="icofont-simple-right"></i>
                                     Civil Law
                                 </a>
                             </li>
                             <li>
                                <a href="javascript:void(0)"
                                 (click)="navigateToSection('section16')"
                                 ><i class="icofont-simple-right"></i>
                                     Corporate and Commericial Law
                                 </a>
                             </li>
                            <li>
                               <a href="javascript:void(0)"
                                (click)="navigateToSection('section17')"
                                ><i class="icofont-simple-right"></i>
                                    Constitutional Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section18')"
                                ><i class="icofont-simple-right"></i>
                                    Labour and Employment Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section19')"
                                ><i class="icofont-simple-right"></i>
                                    Family Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section20')"
                                ><i class="icofont-simple-right"></i>
                                    ADR
                                  
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section21')"
                                ><i class="icofont-simple-right"></i>
                                    Intellectual Property Law
                                 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Quick Links</h3>

                        <ul>
                            <li>
                                <a routerLink="/"
                                    ><i class="icofont-simple-right"></i>
                                    Home</a
                                >
                            </li>
                            <li>
                                <a routerLink="about"
                                    ><i class="icofont-simple-right"></i> About
                                    Us</a
                                >
                            </li>
                            <li>
                                <a routerLink="contact"
                                    ><i class="icofont-simple-right"></i>
                                    Contact</a
                                >
                            </li>
</ul>
                            <br>
                           <h3> Legal Domains</h3>
                           <ul>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section14')"
                                ><i class="icofont-simple-right"></i>
                                    Corporate and Commercial Law
                                </a>
                            </li>
                            <li>
                               <a href="javascript:void(0)"
                                (click)="navigateToSection('section15')"
                                ><i class="icofont-simple-right"></i>
                                    Constitutional Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section16')"
                                ><i class="icofont-simple-right"></i>
                                    Labour and Employment Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section17')"
                                ><i class="icofont-simple-right"></i>
                                    Family Law
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section18')"
                                ><i class="icofont-simple-right"></i>
                                    ADR
                                  
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                (click)="navigateToSection('section19')"
                                ><i class="icofont-simple-right"></i>
                                    Intellectual Property Law
                                 
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)" (click)="navigateToSection('section20')"
                                ><i class="icofont-simple-right"></i>
                                Civil Law
                                 
                                </a>
                            </li>
                            <li>
                                <a
                                href="javascript:void(0)"
                                
                                ><i class="icofont-simple-right"></i>
                                Criminal Law
                                 
                                </a>
                            </li>
                           </ul>
                          
                        
                    </div>
                </div>
            </div> -->

            <div class="col-sm-4 col-lg-4">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>
                        <ul>
                            <li>
                                <span class="location"><i class="icofont-location-pin"></i></span>
                                <span class="address">
                                    Flat No. 205, 2nd Floor,
                                    Mayfair Gardens,Beside Indian Oil Petrol Bunk, Road No. 12,
                                    Banjara Hills, Hyderabad,
                                    Telangana, India - 500034.</span
                                >
                            </li>
                            <li>
                                <span class="tele"><i class="icofont-telephone"></i
                                ></span>
                                <a href="tel:+919030678999">
                                    Office: +91 40 31691387, <br />
                                    +91 40 23349999
                                </a>
                            </li>
                            <li>
                                <i class="icofont-ui-call"></i
                                ><a href="tel:+919030678999">
                                    Cell: +91 9030678999 <br />
                                </a>
                            </li>
                            <li>
                                <i class="icofont-at"></i
                                ><a href="mailto:info&#64;ujassociates.com">
                                    info&#64;ujassociates.com</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>
                            © {{ currentYear }}
                            <a href="/">ujassociates.com </a>
                            | All Rights Reserved
                        </p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/privacy-policy" target="_blank"
                            >Privacy Policy</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
