<!-- <div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Our Blogs</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div> -->

<section class="blog-area blog-area-two">
    <div class="container">
        <div class="row">
            <h2 class="domains">Expertise in Various Legal Domains</h2>

            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                >
                    <a>
                        <img src="assets/img/domain/civil.png" alt="Blog" />
                    </a>
                    <div class="blog-inner">
                        <span>Civil Law</span>
                        <!-- <h3><a>Justice May For You If You Are Innocent</a></h3>
                        <ul>
                            <li><i class="icofont-calendar"></i> 20 Feb 2024</li>
                            <li><i class="icofont-user-alt-7"></i> <a routerLink="/blog">John Doe</a></li>
                        </ul> -->
                        <p>
                            U.J. Associates has extensive experience in civil
                            litigation and advisory services. The firm handles a
                            variety of civil disputes, including property
                            disputes, family law matters, contractual disputes,
                            and tort claims. The lawyers at the firm are skilled
                            in representing clients in matters involving:
                            <br />
                            • Partition suits and property ownership
                            disputes.
                             <br />
                             • Contractual agreements, breach of
                            contract, and specific performance.
                             <br />
                             •Matrimonial issues, including divorce, maintenance,
                            and child custody. <br />
                            • Matters relating to
                            wills, inheritance, and succession. <br />
                            • Tort
                            law, including defamation and negligence claims.
                        </p>
                        <a href="javascript:void(0)" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".4s"
                >
                    <a>
                        <img src="assets/img/domain/criminal.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Criminal Law</span>

                        <p>
                            The firm has a strong criminal law practice,
                            representing clients in criminal cases ranging from
                            minor offences to major crimes. With in-depth
                            knowledge of criminal procedure and substantive
                            criminal law, U.J. Associates provides robust
                            representation in: <br>
                            • Bail applications and
                            anticipatory bail. <br>
                            • Criminal trials involving
                            offences under the Indian Penal Code (IPC). <br>
                            •
                            White-collar crimes such as fraud, forgery, and
                            embezzlement.<br>
                             • Cyber crimes and offences under
                            special statutes like the Prevention of Corruption
                            Act. <br>
                            • Appeals and revisions in criminal matters
                            before the appellate courts.
                        </p>
                        <a href="javascript:void(0)" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".5s"
                >
                    <a>
                        <img src="assets/img/domain/corporate.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Corporate and Commercial Law</span>

                        <p>
                            U.J. Associates provides comprehensive legal
                            solutions to corporate clients, advising on a range
                            of business transactions and commercial disputes.
                            Their expertise the spans across: <br>
                            • Company law,
                            including formation, mergers, acquisitions, and
                            corporate governance.<br>
                             • Insolvency and bankruptcy
                            cases under the Insolvency and Bankruptcy Code
                            (IBC).<br>
                             • Commercial contract drafting, review, and
                            litigation. <br>
                            • Arbitration and dispute resolution in
                            commercial conflicts.<br>
                             • Regulatory compliance and
                            advisory services for companies.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section14')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>

                
            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                >
                    <a>
                        <img src="assets/img/domain/constitution.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Constitutional Law</span>

                        <p>
                            The firm has considerable experience in handling
                            matters relating to constitutional law, including
                            public interest litigation (PIL), writ petitions,
                            and fundamental rights violations. U.J. Associates
                            frequently represents clients in:<br>
                             • Writ petitions
                            (under Articles 32 and 226) concerning the violation
                            of fundamental rights.<br>
                             • Challenges to government
                            actions, policies, and legislation.<br>
                             • Public
                            Interest Litigation in matters affecting public
                            welfare and policy. <br>
                            • Service law disputes involving
                            government employees.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section15')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".5s"
                >
                    <a>
                        <img src="assets/img/domain/labour.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Labour and Employment Law</span>
                        <p>
                            The firm has a robust practice in labour and
                            employment law, representing both employers and
                            employees in industrial disputes, wrongful
                            termination cases, and employee benefits litigation.
                            Key areas of practice <br>include:<br>
                             • Drafting and
                            enforcing employment contracts.<br>
                             • Handling disputes
                            related to wages, termination, and compensation.<br>
                             • Industrial disputes and strikes under the Industrial
                            Disputes Act.<br>
                             • Labour law compliance and advisory
                            services.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section16')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".4s"
                >
                    <a>
                        <img src="assets/img/domain/family.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Family Law</span>

                        <p>
                            U.J. Associates provides expert legal advice and
                            representation in family the law matters, offering
                            compassionate yet firm representation in cases
                           <br> involving: <br>
                            • Divorce and judicial separation.<br>
                             • Custody battles and child support disputes.<br>
                             • Domestic violence cases under the Protection of
                            Women from Domestic Violence Act.<br>
                             • Dowry harassment
                            cases under the Dowry Prohibition Act and Section
                            498A of the IPC.<br>
                             • Maintenance and alimony
                            petitions.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section17')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

        
            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".4s"
                >
                    <a>
                        <img src="assets/img/domain/ADR.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span
                            >Arbitration and Alternative Dispute Resolution
                            (ADR)</span
                        >

                        <p>
                            U.J. Associates excels in arbitration and ADR
                            methods, providing cost-effective and time-saving
                            dispute resolution strategies. The firm represents
                            clients in: <br>
                            • Domestic and international arbitration
                            proceedings.<br>
                             • Mediation and conciliation of
                            disputes in commercial and family law matters.<br>
                             • Enforcing and challenging arbitral awards under the
                            Arbitration and Conciliation Act.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section18')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>

                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div
                    class="blog-item wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                >
                    <a>
                        <img src="assets/img/domain/property.png" alt="Blog" />
                    </a>

                    <div class="blog-inner">
                        <span>Intellectual Property Law</span>

                        <p>
                            The lawyers at U.J. Associates provide specialized
                            services in intellectual property law, and also assisting
                            clients with:<br>
                             • Trademark registration, protection,
                            and infringement cases.<br>
                             • Patent filing,
                            prosecution, and litigation. <br>
                            • Copyright
                            infringement disputes and litigation.<br>
                             • Licensing
                            agreements and IP-related commercial transactions.
                        </p>
                        <a href="javascript:void(0)" (click)="navigateToSection('section19')" class="blog-link">Read More<i class="icofont-simple-right"></i></a>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="case-pagination">
            <ul>
                <li>
                    <a routerLink="/blog"
                        ><i class="icofont-simple-left"></i
                    ></a>
                </li>
                <li><a routerLink="/blog">1</a></li>
                <li><a routerLink="/blog">2</a></li>
                <li><a routerLink="/blog">3</a></li>
                <li>
                    <a routerLink="/blog"
                        ><i class="icofont-simple-right"></i
                    ></a>
                </li>
            </ul>
        </div> -->
    </div>
</section>
