<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/uj-logo.png"  alt="Logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" (click)="scrollToTop()">
                    <img src="assets/img/uj-logo.png" alt="Logo" class="logo-img">

                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="/" class="nav-link">Home</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Uday Kanth</a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Attorneys</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/attorney" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Attorney</a></li>

                                <li class="nav-item"><a routerLink="/attorney-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Attorney Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study</a></li>

                                <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blogs</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/practice" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Practice</a></li>

                                <li class="nav-item"><a routerLink="/practice-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Practice Details</a></li>

                                <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item"><a routerLink="/testimonial" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonial</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="side-nav">
                        <a routerLink="/appointment">Get Appointment</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>