import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { HttpClient } from '@angular/common/http'; 
import { ContactUsService } from '../../services/contact-us.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  @Input() showFullContent: boolean = true;


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private contactUsService: ContactUsService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      msg_subject: [''],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }

  // Easy access to form controls
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    
    if (this.contactForm.invalid) {
      return;
    }

    let payload = {
      name: this.contactForm.value.name,
      email: this.contactForm.value.email,
      phone: this.contactForm.value.phone_number,
      subject: this.contactForm.value.msg_subject,
      message: this.contactForm.value.message
    }

    this.contactUsService.submitContactForm(payload).subscribe(
      (response) => {
        console.log('Form submitted successfully', response);
        this.contactForm.reset();
      },
      (error) => {
        console.error('Error occurred while submitting the form', error);
      }
    );
  }
}
