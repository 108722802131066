<div *ngIf="showFullContent" class="page-title-area page-title-area-two title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Contact Us</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div  class="contact-form contact-form-four pb-100 mt-2">
    <div *ngIf="showFullContent" class="loaction-area">
        <div class="container">
            <div class="row location-bg">
                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-pin"></i>
                        </div>
                        <h3>Location</h3>
                        <ul>
                            <li>Flat No. 205, 2nd Floor, Mayfair Gardens, Beside Indian Oil Petrol Bunk, Road No. 12, Banjara Hills, Hyderabad, Telangana, India - 500034.</li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-call"></i>
                        </div>

                        <h3>Phone</h3>

                        <ul>
                            <li><a href="tel:914045079785">Office: +91 40 31691387</a></li>
                            <li><a href="tel:+914023349999.">Office: +91 40 2334 9999.
                            </a></li>
                            <li><a href="tel:+919030678999.">Cell: +91 9030 678999. 
                            </a></li>

                        </ul>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="location-item">
                        <div class="location-icon">
                            <i class="flaticon-email"></i>
                        </div>
                        
                        <h3>Email</h3>

                        <ul>
                            <li><a href="mailto:info&#64;ujassociates.com">info&#64;ujassociates.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" id="contactForm">
          <div class="row contact-wrap">
            <div class="col-sm-6 col-lg-6">
              <div class="form-group">
                <input type="text" formControlName="name" class="form-control" placeholder="Your Full Name">
                <div *ngIf="f.name.touched && f.name.errors" class="text-danger">
                  <small *ngIf="f.name.errors.required">Name is required.</small>
                  <small *ngIf="f.name.errors.minlength">Name must be at least 3 characters long.</small>
                </div>
              </div>
            </div>
      
            <div class="col-sm-6 col-lg-6">
              <div class="form-group">
                <input type="text" formControlName="phone_number" class="form-control" placeholder="Your Phone">
                <div *ngIf="f.phone_number.touched && f.phone_number.errors" class="text-danger">
                  <small *ngIf="f.phone_number.errors.required">Phone number is required.</small>
                  <small *ngIf="f.phone_number.errors.pattern">Enter a valid 10-digit phone number.</small>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="Your Email">
                <div *ngIf="f.email.touched && f.email.errors" class="text-danger">
                  <small *ngIf="f.email.errors.required">Email is required.</small>
                  <small *ngIf="f.email.errors.email">Enter a valid email address.</small>
                </div>
              </div>
            </div>
      
                <div class="col-sm-6 col-lg-6">
              <div class="form-group">
                <input type="text" formControlName="msg_subject" class="form-control" placeholder="Subject">
              </div>
            </div>
      
            <div class="col-md-12 col-lg-12">
              <div class="form-group">
                <textarea formControlName="message" class="form-control" cols="30" rows="8" placeholder="Case Description"></textarea>
                <div *ngIf="f.message.touched && f.message.errors" class="text-danger">
                  <small *ngIf="f.message.errors.required">Case description is required.</small>
                  <small *ngIf="f.message.errors.minlength">Case description  must be at least 10 characters long.</small>
                </div>
              </div>
            </div>
      
            <div class="col-md-12 col-lg-12">
              <div class="text-center">
                <button type="submit" class="contact-btn mb-4" [disabled]="contactForm.invalid">Submit Case</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      
      
</div>

<div *ngIf="showFullContent" class="map-area">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863114898!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1591087986221!5m2!1sen!2sbd"></iframe> -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.0637194957435!2d78.43456687124272!3d17.408729465707886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9724911b5481%3A0xc6475c70186a8a4c!2sMayfair%20Garden%2C%20Road%20No.%2012%2C%20near%20Ohri&#39;s%20Uppu%20Telugu%20Kitchen%2C%20Fortune%20Enclave%2C%20Sri%20Ram%20Nagar%20Colony%2C%20Banjara%20Hills%2C%20Hyderabad%2C%20Telangana%20500034!5e0!3m2!1sen!2sin!4v1727449913669!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>