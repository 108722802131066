import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(  private router: Router) { }
  
  navigateToSection(sectionId: string) {
    this.router.navigate(['/practice-details'], { queryParams: { section: sectionId } });
  }


  ngOnInit(): void {
  }

}
