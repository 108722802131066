import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-practice-details',
  templateUrl: './practice-details.component.html',
  styleUrls: ['./practice-details.component.scss']
})
export class PracticeDetailsComponent implements OnInit {
  backgroundImage: string;
  selectedContent: string = 'section1'; 
  sectionTitles: { [key: string]: string } = {
    'section1': 'Criminal Cases',
    'section2': 'Right to Information (RTI) Act',
    'section3': 'Writ Petitions',
    'section4': 'Civil Cases',
    'section5': 'Service Matters',
    'section6': 'Child Rights',
    'section7': 'Land Acquisition',
    'section8': 'Consumer Rights',
    'section9': 'Human Rights',
    'section10': 'Debt Recovery Tribunals',
    'section11': 'Comprehensive and Strategic Legal Solutions',
    'section12': 'Tax Laws',
    'section13': 'Additional Specialized Legal Areas',
    'section14': 'Criminal Law',
    'section15': 'Civil Law',
    'section16': 'Corporate and Commercial Law',
    'section17': 'Constitutional Law',
    'section18': 'Labour and Employment Law',
    'section19': 'Family Law',
    'section20': ' Arbitration and Alternative Dispute Resolution (ADR)',
    'section21': ' Intellectual Property Law',
  };

  sectionImages = {
    'section15': 'assets/img/domain/civil.png',
    'section16': 'assets/img/domain/corporate.png',
    'section14': 'assets/img/domain/criminal.png',
    'section17': 'assets/img/domain/constitution.png',
    'section18': 'assets/img/domain/labour.png',
    'section19': 'assets/img/domain/family.png',
    'section20': 'assets/img/domain/ADR.png',
    'section21': 'assets/img/domain/property.png',
    'section1': 'assets/img/domain/cases.png',
    'section2': 'assets/img/domain/ROI.png',
    'section3': 'assets/img/domain/petitions.png',
    'section4': 'assets/img/domain/civil-cases.png',
    'section5': 'assets/img/domain/services.png',
    'section6': 'assets/img/domain/child.png',
    'section7': 'assets/img/domain/land.png',
    'section8': 'assets/img/domain/consumer.png',
    'section9': 'assets/img/domain/human.png',
    'section10': 'assets/img/domain/debt.png',
    'section11': 'assets/img/domain/legal.png',
    'section12': 'assets/img/domain/tax.png',
    'section13': 'assets/img/domain/additional.png',
  };
  @ViewChild('section1') section1!: ElementRef;
  @ViewChild('section2') section2!: ElementRef;
  @ViewChild('section3') section3!: ElementRef;
  @ViewChild('section4') section4!: ElementRef;
  @ViewChild('section5') section5!: ElementRef;
  @ViewChild('section6') section6!: ElementRef;
  @ViewChild('section7') section7!: ElementRef;
  @ViewChild('section8') section8!: ElementRef;
  @ViewChild('section9') section9!: ElementRef;
  @ViewChild('section10') section10!: ElementRef;
  @ViewChild('section11') section11!: ElementRef;
  @ViewChild('section12') section12!: ElementRef;
  @ViewChild('section13') section13!: ElementRef;
  @ViewChild('section14') section14!: ElementRef;
  @ViewChild('section15') section15!: ElementRef;
  @ViewChild('section16') section16!: ElementRef;
  @ViewChild('section17') section17!: ElementRef;
  @ViewChild('section18') section18!: ElementRef;
  @ViewChild('section19') section19!: ElementRef;
  @ViewChild('section20') section20!: ElementRef;
  @ViewChild('section21') section21!: ElementRef;


  constructor(private route: ActivatedRoute) {}

  scrollToSection(id: string) {
    setTimeout(() => {
      let el = document.getElementById(id);
      if (el) {
          const headerHeight = 90; 
          const elementPosition = el.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - headerHeight;
  
        window.scrollTo({
          top: offsetPosition,
          left: 0,
          behavior: "smooth", 
        });
      }
    }, 100);
  }

  selectSection(section: string): void {
    this.selectedContent = section;
    this.scrollToSection(section);
  }

  ngOnInit() {
    this.backgroundImage = this.sectionImages[this.selectedContent];
    this.route.queryParams.subscribe(params => {
      const section = params['section'];
      if (section) {
        this.selectSection(section); 
      }
    });
  }


  onSectionClick(sectionKey: string) {
    this.selectedContent = sectionKey;

    // Ensure the section key exists in sectionImages
    if (this.sectionImages[sectionKey]) {
      this.backgroundImage = this.sectionImages[sectionKey];
    } else {
      // Optionally handle missing image cases
      this.backgroundImage = 'assets/images/default-bg.jpg'; // Set a fallback image
    }
    this.scrollToSection(sectionKey);
  }
 
  }
  



