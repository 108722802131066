import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from '../../services/contact-us.service';
import { Router } from '@angular/router';
import { legalDomainsService } from '../../services/legalDomain.service';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  legalDomains: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private contactUsService: ContactUsService,
    private router: Router,
    private legalDomainsService: legalDomainsService
  ) { }

  navigateToSection(sectionId: string) {
    this.router.navigate(['/practice-details'], { queryParams: { section: sectionId } });
  }



  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      msg_subject: [''],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
    this.legalDomainsService.getlegalDomains().subscribe(data => {
      this.legalDomains = data.legalDomains;})
  }

  // Easy access to form controls
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    
    if (this.contactForm.invalid) {
      return;
    }

    let payload = {
      name: this.contactForm.value.name,
      email: this.contactForm.value.email,
      phone: this.contactForm.value.phone_number,
      subject: this.contactForm.value.msg_subject,
      message: this.contactForm.value.message
    }

    this.contactUsService.submitContactForm(payload).subscribe(
      (response) => {
        console.log('Form submitted successfully', response);
        this.contactForm.reset();
      },
      (error) => {
        console.error('Error occurred while submitting the form', error);
      }
    );
  }


  }

